// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bR_kz";
export var caseStudyBackground__bgColor = "bR_kp";
export var caseStudyBackground__lineColor = "bR_kq";
export var caseStudyHead__imageAbsolute = "bR_kJ";
export var caseStudyHead__imageWrapper = "bR_kn";
export var caseStudyProjectsSection = "bR_kB";
export var caseStudyQuote__bgRing = "bR_kw";
export var caseStudyTech = "bR_ky";
export var caseStudyVideo = "bR_kD";
export var caseStudyVideo__ring = "bR_kF";
export var caseStudy__bgDark = "bR_km";
export var caseStudy__bgLight = "bR_kl";